<template>
  <v-fade-transition leave-absolute>
    <v-card v-if="caseData != null" tile elevation="3">
      <v-card-title class="pa-0"> </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <p class="my-0 py-0 text-body-2 black--text font-weight-bold">
                <v-icon
                  small
                  @click="showCaseStatus = !showCaseStatus"
                  v-if="showCaseStatus == false"
                >
                  mdi-arrow-right-drop-circle
                </v-icon>
                <v-icon
                  small
                  @click="showCaseStatus = !showCaseStatus"
                  v-if="showCaseStatus == true"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                Case Status
              </p>
              <v-divider class="my-0 py-0"></v-divider>
            </v-col>
            <v-col cols="auto" class="py-0 my-0" v-if="showCaseStatus == true">
              <v-btn
                elevation="0"
                color="#FF4D00"
                dark
                width="100"
                tile
                :outlined="caseData.case_status != 1"
                @click="changeStatus(1)"
              >
                <h5 class="py-5">NEW</h5>
              </v-btn>
              <v-btn
                elevation="0"
                color="#FF4D00"
                dark
                width="100"
                tile
                :outlined="caseData.case_status != 2"
                @click="changeStatus(2)"
              >
                <h5 class="py-5">ASSESS</h5>
              </v-btn>
              <v-btn
                elevation="0"
                color="#FF4D00"
                dark
                width="100"
                tile
                :outlined="caseData.case_status != 3"
                @click="changeStatus(3)"
              >
                <h5 class="py-5">RESEARCH</h5>
              </v-btn>
              <v-btn
                elevation="0"
                color="#FF4D00"
                dark
                tile
                width="100"
                :outlined="caseData.case_status != 4"
                @click="changeStatus(4)"
              >
                <h5 class="py-5">SOLUTION</h5>
              </v-btn>
              <v-btn
                elevation="0"
                color="#FF4D00"
                dark
                tile
                width="100"
                :outlined="caseData.case_status != 5"
                @click="changeStatus(5)"
              >
                <h5 class="py-5">CLOSED</h5>
              </v-btn>
            </v-col>
            <v-col
              cols="auto"
              class="py-0 my-0 ml-auto"
              v-if="caseData.case_status == 5 && !isReadOnly"
            >
              <v-btn
                elevation="0"
                color="#083272"
                dark
                tile
                width="100"
                @click="showReopenModal"
                >REOPEN</v-btn
              >
            </v-col>
            <v-col
              cols="auto"
              class="py-0 my-0 ml-auto"
              v-if="caseData.case_status == 5 && isReadOnly"
            >
              <v-btn elevation="0" color="info" dark tile>
                READONLY
                <v-icon class="ml-2">mdi-clock-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="myInfo.user_level > 3">
            <v-col cols="12">
              <p class="my-0 py-0 text-body-2 black--text font-weight-bold">
                <v-icon
                  small
                  @click="showCaseEscalation = !showCaseEscalation"
                  v-if="showCaseEscalation == false"
                >
                  mdi-arrow-right-drop-circle
                </v-icon>
                <v-icon
                  small
                  @click="showCaseEscalation = !showCaseEscalation"
                  v-if="showCaseEscalation == true"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                Case escalation
              </p>
              <v-divider class="my-0 py-0"></v-divider>
            </v-col>
            <v-col
              cols="12"
              md="auto"
              v-if="showCaseEscalation"
              class="mb-0 pb-0"
            >
              <v-checkbox
                class="pa-0 ma-0"
                v-model="case_escalation.case_is_escalation"
                :label="`Escalation`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="2" v-if="showCaseEscalation" class="mt-0 pt-0">
              <v-text-field
                v-if="case_escalation.case_is_escalation"
                hide-details
                outlined
                dense
                label="ID"
                v-model="case_escalation.case_escalation_id"
              ></v-text-field>
            </v-col>
            <v-col cols="auto" v-if="showCaseEscalation" class="mt-0 pt-0">
              <v-btn color="#FF4D00" dark @click="saveEscalation">SAVE</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="myInfo.user_level > 3">
            <v-col cols="12">
              <p class="my-0 py-0 text-body-2 black--text font-weight-bold">
                <v-icon
                  small
                  @click="showCaseAdminMemo = !showCaseAdminMemo"
                  v-if="showCaseAdminMemo == false"
                >
                  mdi-arrow-right-drop-circle
                </v-icon>
                <v-icon
                  small
                  @click="showCaseAdminMemo = !showCaseAdminMemo"
                  v-if="showCaseAdminMemo == true"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                Case Admin's Memo (관리자에게만 보이는 메모 입니다)
              </p>
              <v-divider class="my-0 py-0"></v-divider>
            </v-col>
            <v-col cols="12" md="12" v-if="showCaseAdminMemo" class="mb-0 pb-0">
              <v-textarea
                solo
                label="Memo Here"
                v-model="case_adminmemo"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              md="auto"
              v-if="showCaseAdminMemo"
              class="mt-0 pt-0 ml-auto"
            >
              <v-btn color="#FF4D00" dark @click="saveAdminMemo">SAVE</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="caseData.case_parent_id != null">
            <v-col cols="12">
              <p class="my-0 py-0 text-body-2 black--text font-weight-bold">
                <v-icon
                  small
                  @click="showCaseReopenInfo = !showCaseReopenInfo"
                  v-if="showCaseReopenInfo == false"
                >
                  mdi-arrow-right-drop-circle
                </v-icon>
                <v-icon
                  small
                  @click="showCaseReopenInfo = !showCaseReopenInfo"
                  v-if="showCaseReopenInfo == true"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                Reopen Information
              </p>
              <v-divider class="my-0 py-0"></v-divider>
            </v-col>
            <v-col cols="12" class="py-0" v-if="showCaseReopenInfo == true">
              <v-container fluid class="px-0">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      Parent Case ID
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      <span
                        @click="
                          $router.push(`/case/${caseData.case_parent_uuid}`)
                        "
                        style="cursor: pointer"
                      >
                        <v-icon> mdi-link </v-icon>
                        {{ caseData.case_parent_id }}
                      </span>
                    </p>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      Reopen reason
                    </p>
                    <div
                      class="text-subtitle-2 pa-3 pt-5"
                      style="border: solid 1px #083272"
                    >
                      <div v-html="caseData.case_reopen_reason" />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="my-0 py-0 text-body-2 black--text font-weight-bold">
                <v-icon
                  small
                  @click="showCaseInfo = !showCaseInfo"
                  v-if="showCaseInfo == false"
                >
                  mdi-arrow-right-drop-circle
                </v-icon>
                <v-icon
                  small
                  @click="showCaseInfo = !showCaseInfo"
                  v-if="showCaseInfo == true"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                Case Information
              </p>
              <v-divider class="my-0 py-0"></v-divider>
            </v-col>
            <v-col cols="12" class="py-0" v-if="showCaseInfo == true">
              <v-container fluid class="px-0">
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      고객사
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.TB_INFO_COMPANY_LIST.company_name }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      작성자
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                      v-if="caseData.TB_USER_ACCOUNT_LIST"
                    >
                      {{ caseData.TB_USER_ACCOUNT_LIST.user_tech_level }}
                      {{ caseData.TB_USER_ACCOUNT_LIST?.user_name_first_eng }}
                      {{ caseData.TB_USER_ACCOUNT_LIST?.user_name_last_eng }}
                      ( {{ caseData.TB_USER_ACCOUNT_LIST?.user_id }} )
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                      v-if="caseData.TB_USER_ACCOUNT_LIST == null"
                    >
                      휴면 계정
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="caseData.case_client_user_name != null"
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      고객사 담당자
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_client_user_name }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="caseData.case_client_user_mail != null"
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      고객사 담당자 이메일
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_client_user_mail }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      계약
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.TB_INFO_CONTRACT_LIST.contract_name }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      고객사 담당자 전화번호
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_client_user_phone }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      작성 일시
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ createTime }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="
                      caseData.case_type != 'Administrative' &&
                      caseData.case_type != 'Admst'
                    "
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      솔루션(제품)
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.TB_INFO_PRODUCT_LIST.product_name }}
                      <span
                        class="red--text font-weight-bold mx-4"
                        v-if="product_expired"
                      >
                        *** Expired 된 제품 입니다. ***
                      </span>
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="
                      caseData.case_type == 'Technical' &&
                      caseData.case_product_type_cloud
                    "
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      Org ID
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_product_org_id }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="
                      caseData.case_type == 'Technical' &&
                      caseData.case_product_type_cloud
                    "
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      Secure Agent
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_product_secure_agent }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="
                      caseData.case_type == 'Technical' &&
                      caseData.case_product_type_etc
                    "
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      Activity Type
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_product_activity_type }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="
                      caseData.case_type == 'Technical' &&
                      caseData.case_product_type_etc
                    "
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      Environment Type
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_product_environment_type }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="caseData.case_type == 'Fulfillment'"
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      Environment Type
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_product_environment_type }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="
                      caseData.case_type != 'Administrative' &&
                      caseData.case_type != 'Admst'
                    "
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      버전
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_product_version }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      중요도
                    </p>
                    <div
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      <strong>{{
                        caseData.TB_PRST_PRIORITY_LEVEL.level_name
                      }}</strong>
                      <v-btn
                        x-small
                        class="ml-5"
                        color="info"
                        v-if="myInfo.user_level > 3"
                        @click="changePriorityBtn"
                      >
                        변경
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                    v-if="caseData.case_type == 'Administrative'"
                  >
                    <p
                      class="text-body-2 black--text font-weight-bold py-0 my-0"
                    >
                      Problem Area
                    </p>
                    <p
                      class="text-subtitle-2 pa-1 mb-2"
                      style="border: solid 1px #083272"
                    >
                      {{ caseData.case_problem_area }}
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" class="pt-0" v-if="showCaseInfo == true">
              <p class="text-body-2 black--text font-weight-bold py-0 my-0">
                Description
              </p>
              <div
                class="text-subtitle-2 pa-3 pt-5"
                style="border: solid 1px #083272"
              >
                <h2 v-html="getContents().case_subject"></h2>
                <v-divider class="my-2"></v-divider>
                <div id="contentbox" v-html="getContents().case_description" />
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
              v-if="showCaseInfo == true && caseData.case_type == 'Technical'"
            >
              <p class="text-body-2 black--text font-weight-bold py-0 my-0">
                Error Log
              </p>
              <div
                class="text-subtitle-2 pa-3 pt-5"
                style="border: solid 1px #083272"
              >
                <div id="contentbox" v-html="getContents().case_errorlog" />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p class="my-0 py-0 text-body-2 black--text font-weight-bold">
                <v-icon
                  small
                  @click="showAttachments = !showAttachments"
                  v-if="showAttachments == false"
                >
                  mdi-arrow-right-drop-circle
                </v-icon>
                <v-icon
                  small
                  @click="showAttachments = !showAttachments"
                  v-if="showAttachments == true"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                Attachments
              </p>
              <v-divider class="my-0 py-0"></v-divider>
            </v-col>
            <v-col cols="auto" class="ml-auto py-0 my-0">
              <input
                id="attachment"
                name="attachment"
                ref="attachment"
                type="file"
                @change="selectedFiles"
                hidden
              />
              <v-btn
                outlined
                color="success"
                small
                class="px-5"
                @click="chooseFiles"
                :loading="btnLoading"
                v-if="!isReadOnly"
              >
                <v-icon class="mr-1">mdi-file</v-icon>New File
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="showAttachments">
              <DataGrid
                :data="attachments"
                :filterable="false"
                :pageSize="5"
                :pagination="true"
                :virtualScroll="true"
                :loading="loading"
                :clickToEdit="true"
                :columnResizing="true"
                style="width: 100%"
              >
                <GridColumnGroup>
                  <GridHeaderRow>
                    <GridColumn
                      field="file_name"
                      title="File Name"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                    <GridColumn
                      field="file_size"
                      title="File Size"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                    <GridColumn
                      field="file_mimetype"
                      title="Mime Type"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                  </GridHeaderRow>
                </GridColumnGroup>
                <GridColumnGroup :frozen="true" width="400" align="right">
                  <GridHeaderRow>
                    <GridColumn
                      field="createdAt"
                      title="Created Time"
                      align="center"
                      :sortable="true"
                      size="200"
                    >
                      <template slot="body" slot-scope="scope">
                        {{ toKorTime(scope.row.createdAt) }}
                      </template>
                    </GridColumn>

                    <GridColumn
                      title="Option"
                      :filterable="false"
                      align="center"
                      size="200"
                    >
                      <template slot="body" slot-scope="scope">
                        <LinkButton
                          iconCls="icon-save"
                          class="mx-1"
                          @click="download(scope.row)"
                          :disabled="btnLoading"
                        >
                          Download
                        </LinkButton>
                        <LinkButton
                          iconCls="icon-remove"
                          class="mx-1"
                          @click="remove(scope.row)"
                          v-if="myInfo.user_level > 3"
                          :disabled="btnLoading"
                        >
                          Remove
                        </LinkButton>
                      </template>
                    </GridColumn>
                  </GridHeaderRow>
                </GridColumnGroup>
              </DataGrid>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="my-0 py-0 text-body-2 black--text font-weight-bold">
                <v-icon
                  small
                  @click="showCaseThread = !showCaseThread"
                  v-if="showCaseThread == false"
                >
                  mdi-arrow-right-drop-circle
                </v-icon>
                <v-icon
                  small
                  @click="showCaseThread = !showCaseThread"
                  v-if="showCaseThread == true"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                Case Thread
              </p>
              <v-divider class="my-0 py-0"></v-divider>
            </v-col>
            <v-col cols="12" v-if="showCaseThread">
              <CaseThread :caseUUID="caseData.case_uuid" :caseData="caseData" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-dialog v-model="showReopenMdl" width="800">
        <v-card tile>
          <v-card-title class="pa-0">
            <v-toolbar color="#083272" dark class="">
              <v-toolbar-title>
                <h3 class="font-weight-bold suptitle">REOPEN CASE</h3>
              </v-toolbar-title>
              <v-btn color="error" @click="closeReopenModal" class="ml-auto">
                CANCEL <v-icon class="ml-2">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-row>
                <v-col>
                  <tiptap-vuetify
                    v-model="reopen_reson"
                    :extensions="extensions"
                    placeholder="Reopen 사유를 자세히 입력하십시오."
                    :disableInputRules="true"
                    :disablePasteRules="true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto ml-auto">
                  <v-btn color="success" @click="requestReopen" class="ml-1">
                    REOPEN <v-icon class="ml-2">mdi-check</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showChangePriorityDialog" width="400px">
        <v-card>
          <v-card-title> 중요도 변경 </v-card-title>
          <v-card-text>
            <v-select
              outlined
              :items="priorityList"
              v-model="to_change_priority"
              dense
              item-text="level_name"
              item-value="priority_level"
            ></v-select>
            <v-btn block color="success" tile @click="putCasePriority"
              >완료</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-fade-transition>
</template>

<script>
import { changeCaseStatus } from "@/api/CaseStatus";
import { remove, download, uploadInCase } from "@/api/Files";
import {
  reopenCase,
  putCaseEscalation,
  putCasePriority,
  putCaseAdminMemo,
} from "@/api/Case";
import { getPriority } from "@/api/Presets";
import CaseThread from "@/components/Views/ViewCase/card/CaseThread";
import moment from "moment";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  // Image,
} from "@jwmsg/tiptap-vuetify";
export default {
  components: {
    CaseThread,
    TiptapVuetify,
  },
  props: {
    refreshFunc: {
      type: Function,
    },
    caseData: {
      type: Object,
      require: true,
    },
    product_expired: {
      type: [Boolean, Object],
      require: false,
      default: null,
    },
  },
  computed: {
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
    createTime() {
      return moment(this.caseData.createdAt).format("yyyy-MM-DD HH:mm:ss");
    },
    isAdmin() {
      return this.myInfo.user_level > 3;
    },
    isReadOnly() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (!publicSetting.READONLY_ON) return false;
      if (this.isAdmin) return false;
      const readonly_dow = publicSetting.READONLY_DOW.split(",");
      console.log();
      const readonly_end_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.READONLY_END_TIME
      );
      const readonly_start_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.READONLY_START_TIME
      );
      const today = moment();

      const is_inTime =
        readonly_end_time > today && readonly_start_time < today;
      const is_inDow = readonly_dow.find((e) => e == today.days()) != null;

      return !(is_inTime && is_inDow);
    },
  },
  mounted() {
    this.listAttachments();
    this.getEscalation();
    this.getAdminMemo();
  },
  watch: {
    caseData() {
      this.listAttachments();
      this.getEscalation();
      this.getAdminMemo();
    },
  },
  methods: {
    async changePriorityBtn() {
      this.showChangePriorityDialog = true;
      const data = await getPriority();
      this.priorityList = data.data;
    },
    async putCasePriority() {
      await putCasePriority(this.caseData.case_uuid, this.to_change_priority);
      this.showChangePriorityDialog = false;
      this.$router.go();
    },
    toKorTime(time) {
      const date = new Date(time);
      return date.toLocaleString("Seoul");
    },
    async selectedFiles() {
      this.btnLoading = true;
      const attachment = this.$refs.attachment;
      const formData = new FormData();
      if (attachment.files.length > 0) {
        const uploadFile = attachment.files[0];
        if (uploadFile.size > 2048 * 1024 * 1024) {
          this.btnLoading = false;
          return alert("2GB 이하의 파일만 업로드가 가능합니다.");
        }
        alert(
          "업로드 용량이 클수록 오래 기다려 주셔야 합니다.\n업로드 로딩이 끝날때 까지 대기해 주시기 바랍니다."
        );
        formData.append("file", uploadFile);
        const data = await uploadInCase(formData, this.caseData.case_uuid);
        if (data.success) {
          this.$router.go();
        }
        this.$emit("input", this.attachmentItems);
      } else {
        this.selectedFile = null;
      }
      this.btnLoading = false;
    },
    getEscalation() {
      if (this.caseData == null) return;
      if (this.caseData.case_is_escalation != null)
        this.case_escalation.case_is_escalation =
          this.caseData.case_is_escalation;
      if (this.caseData.case_escalation_id != null)
        this.case_escalation.case_escalation_id =
          this.caseData.case_escalation_id;
    },
    getAdminMemo() {
      if (this.caseData == null) return;
      if (this.caseData.case_adminmemo != null)
        this.case_adminmemo = this.caseData.case_adminmemo;
    },
    async saveEscalation() {
      const data = await putCaseEscalation(
        this.caseData.case_uuid,
        this.case_escalation.case_is_escalation,
        this.case_escalation.case_escalation_id
      );
      if (data.success) {
        alert("저장했습니다!");
      } else {
        alert("저장에 실패했습니다!");
      }
    },
    async saveAdminMemo() {
      const data = await putCaseAdminMemo(
        this.caseData.case_uuid,
        this.case_adminmemo
      );
      if (data.success) {
        alert("저장했습니다!");
      } else {
        alert("저장에 실패했습니다!");
      }
    },
    chooseFiles() {
      const attachment = this.$refs.attachment;
      attachment.click();
    },
    getContents() {
      if (
        this.caseData.case_type == "Technical" ||
        this.caseData.case_type == "Techn"
      ) {
        return this.caseData.TB_DATA_CASE_TECH_LIST;
      }
      if (
        this.caseData.case_type == "Fulfillment" ||
        this.caseData.case_type == "Fulfill"
      ) {
        return this.caseData.TB_DATA_CASE_FULFILL_LIST;
      }
      if (
        this.caseData.case_type == "Administrative" ||
        this.caseData.case_type == "Admst"
      ) {
        return this.caseData.TB_DATA_CASE_ADMST_LIST;
      }
      return (
        this.caseData.TB_DATA_CASE_TECH_LIST ||
        this.caseData.TB_DATA_CASE_FULFILL_LIST ||
        this.caseData.TB_DATA_CASE_ADMST_LISTF
      );
    },
    async download(item) {
      this.btnLoading = true;
      alert(
        "파일 용량이 클수록 오래 기다려 주셔야 합니다.\n다운로드 로딩이 끝날때 까지 대기해 주시기 바랍니다."
      );
      await download(item.file_uuid);
      this.btnLoading = false;
    },
    async remove(item) {
      this.btnLoading = true;
      const res = await remove(item.file_uuid);
      if (res.success) {
        alert("삭제되었습니다.");
        this.$router.go();
      }
      this.btnLoading = false;
    },
    async changeStatus(case_status) {
      if (case_status == this.caseData.case_status) {
        return;
      }
      const changePermitLevel = [4, 5, 6, 7];
      const hasPermit = changePermitLevel.includes(this.myInfo.user_level);
      if (!hasPermit && case_status == 5) {
        // const conf = prompt(
        //   'CASE를 닫으면 더이상 Thread를 추가 할 수 없으며,\nCASE의 상태를 복구할 수 없습니다.\n계속진행하시려면 "close" 를 입력 후 확인버튼을 누르세요.'
        // );
        // if (conf == null) {
        //   return;
        // }
        // if (conf != "close") {
        //   alert("입력값이 올바르지 않습니다.");
        //   return this.changeStatus(case_status);
        // }
        alert(
          "정책변경에 의해 Thread 를 생성하여 관리자에게 Close 를 요청하시기 바랍니다."
        );
        return;
      }
      if (hasPermit || case_status == 5) {
        await changeCaseStatus(case_status, this.caseData.case_uuid);
        await this.refreshFunc();
      }
    },
    listAttachments() {
      if (this.caseData == null) return;
      if (this.caseData.TB_JOIN_CASE_FILEs == null) return;
      this.attachments = this.caseData.TB_JOIN_CASE_FILEs.map(
        (e) => e.TB_DATA_FILE_LIST
      ).filter((e) => e != null);
    },
    showReopenModal() {
      this.reopen_reson = "";
      this.showReopenMdl = true;
    },
    closeReopenModal() {
      this.showReopenMdl = false;
    },
    async requestReopen() {
      if (this.is_reopen_proceed) {
        return alert("처리중입니다. 잠시만 기다려주십시오.");
      }
      this.is_reopen_proceed = true;
      if (this.reopen_reson == "") {
        return alert("reopen 사유를 입력하십시오.");
      }
      const data = await reopenCase(this.caseData.case_uuid, this.reopen_reson);
      if (data.success) {
        this.reopen_reson = "";
        this.$router.push(`/case/${data.data.case_uuid}`);
      } else {
        this.is_reopen_proceed = false;
      }
    },
  },
  data() {
    return {
      showCaseStatus: true,
      showCaseInfo: true,
      showCaseThread: true,
      showAttachments: true,
      showCaseReopenInfo: true,
      showReopenMdl: false,
      showCaseEscalation: true,
      showCaseAdminMemo: true,
      showChangePriorityDialog: false,
      btnLoading: false,
      attachments: [],
      attachmentHeaders: [
        { text: "Name", value: "file_name" },
        { text: "Size", value: "file_size" },
        { text: "Mimetype", value: "file_mimetype" },
        { text: "CreatedAt", value: "createdAt" },
        { text: "Actions", value: "actions" },
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
        // Image,
      ],
      reopen_reson: "",
      case_escalation: {
        case_is_escalation: false,
        case_escalation_id: "",
      },
      case_adminmemo: null,
      priorityList: [],
      to_change_priority: 1,
    };
  },
};
</script>

<style>
#contentbox p:after {
  content: "";
  display: inline-block;
  width: 0px;
}
</style>
